import './Home.css';
import movies from './data/movies.json';

function Home() {
  return <>
          <header className='header'>Movies App</header>
          <main className='main'>
            <div className='container'>
              {movies.map((movie) => (
                <div className='movie' key={movie.imdbID}>
                  <img className='poster' src={movie.Images[1]} alt={movie.Title} />
                  <div className='title'>{movie.Title} </div>
                </div>
              ))}

              
            </div>
          </main>
        </>
}

export default Home;
